import React from 'react';
import Helmet from 'react-helmet';
import { Link } from 'gatsby';
import PropTypes from 'prop-types';

import useSiteMetadata from '../../components/SiteMetadata';
import BasicMetadata from '../../components/Layout/BasicMetadata';
import FooterScripts from '../../components/Layout/FooterScripts';
import '../../sass/ghost.scss';

const SubscribeThankYouPage = ({ siteTitle }) => {
  return (
    <>
      <Helmet>
        <title>Subscribe | {siteTitle}</title>
        <html className="ghost-cms" />
      </Helmet>
      <div className="gh-app">
        <div className="gh-viewport">
          <main className="gh-main" role="main">
            <div className="gh-flow">
              <header className="gh-flow-head">
                <nav className="gh-flow-nav">
                  <Link to="/" className="gh-flow-back">
                    <i className="icon-arrow-left"></i> Back
                  </Link>
                </nav>
              </header>
              <div className="gh-flow-content-wrap">
                <section className="gh-flow-content">
                  <header>
                    <h1>Subscribed!</h1>
                  </header>

                  <p>
                    You've successfully subscribed to <em>{siteTitle}</em> 🎉.
                  </p>
                </section>
              </div>
            </div>
          </main>
        </div>
      </div>
    </>
  );
};

SubscribeThankYouPage.propTypes = {
  siteTitle: PropTypes.string.isRequired,
};

const SubscribeThankYou = () => {
  const siteMeta = useSiteMetadata();
  console.log(siteMeta);

  return (
    <>
      <BasicMetadata
        title={siteMeta.title}
        description={siteMeta.description}
      />
      <SubscribeThankYouPage siteTitle={siteMeta.title} />
      <FooterScripts />
    </>
  );
};

export default SubscribeThankYou;
